import React from "react"
import { Helmet } from "react-helmet"
import { getStrapiMedia } from "@/utils/media";

const Seo = ({ page, site, fallback }) => {
  console.log(page, site);

  const pageTitle = page ? page.title : false;
  const siteTitle = site ? site.title : false;
  const fallbackTitle = fallback ? fallback.title : false;

  const pageDescription = page ? page.description : false;
  const siteDescription = site ? site.description : false;
  const fallbackDescription = fallback ? fallback.description : false;

  const pageImage = page ? page.image.url : false;
  const siteImage = site ? site.image.url : false;

  const getTitle = () => {
    if (pageTitle) {
      if (siteTitle) {
        return `${pageTitle} | ${siteTitle}`
      } else {
        return pageTitle;
      }
    } else if (siteTitle) {
      return siteTitle;
    } else {
      return fallbackTitle;
    }
  }

  const getDescription = () => {
    if (pageDescription) {
      return pageDescription;
    } else if (siteDescription) {
      return siteDescription;
    } else {
      return fallbackDescription;
    }
  }

  const getImage = () => {
    if (pageImage) {
      return pageImage;
    } else {
      return siteImage;
    }
  }

  return (
    <Helmet title={getTitle()}>
      <meta name="description" content={getDescription()} />
      <meta name="image" content={getImage()} />
      <meta property="og:url" content={"https://jaylenforcolorado.com"} />
      <meta property="og:title" content={getTitle()} />
      <meta property="og:description" content={getDescription()} />
      <meta property="og:image" content={getImage()} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={getTitle()} />
      <meta name="twitter:description" content={getDescription()} />
      <meta name="twitter:image" content={getImage()} />
    </Helmet>
  );
};

export default Seo;
